import jQuery from 'jquery';
import './jquery.oska-spinner';
import './modal';
import './country_modal';
import './authorize_net';
import './pickup_place_address';


window.htmx = require('htmx.org').default;

window.$ = window.jQuery = jQuery
