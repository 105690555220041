import { Modal } from 'bootstrap';
import jQuery from 'jquery';

document.addEventListener('DOMContentLoaded', function () {

    (function ($) {

        var modal_element = document.getElementById('modal-show-authorizenet');
        if (modal_element) {

            if (!window.AuthorizeNetPopup)
                window.AuthorizeNetPopup = {};

            if (!AuthorizeNetPopup.options)
                AuthorizeNetPopup.options = {onPopupClosed: null};

            var modalInstance = new Modal(modal_element, {
                backdrop: 'static', // Zakázání zavírání kliknutím mimo modal
                keyboard: false     // Zakázání zavírání klávesou Escape
            });

            AuthorizeNetPopup.closePopup = function () {
                modalInstance.hide();
                $('#iframeAuthorizeNet').val('src', "/en/cart/empty/");
                if (AuthorizeNetPopup.options.onPopupClosed)
                    AuthorizeNetPopup.options.onPopupClosed();
            };

            AuthorizeNetPopup.openPopup = function () {
                modalInstance.show();
                var ifrm = $('#iframeAuthorizeNet');
                var form = document.forms["form-order-confirm"];
                var width = "445px";
                var height = "600px";

                var screen_width = window.innerWidth;
                if (screen_width > 1000) {
                    width = "995px";
                    height = "605px";
                } else if (screen_width > 760) {
                    width = "755px";
                    height = "710px";
                }

                $.getJSON("/en/cart/cart_authorizenet_get_token/", function (data, status, xhr) {
                    if (data['error']) {
                        $("#error_message").html(data['error']);
                    }
                    else {
                        $("#popupToken").val(data['token']);
                        ifrm.css({width: width, height: height});
                        form.action = data['payment_page_url'];
                        form.submit();
                    }
                });
            };

            AuthorizeNetPopup.onReceiveCommunication = function (querystr) {
                var params = parseQueryString(querystr);
                switch (params["action"]) {
                    case "successfulSave":
                        AuthorizeNetPopup.closePopup();
                        break;
                    case "cancel":
                        AuthorizeNetPopup.closePopup();
                        break;
                    case "transactResponse":
                        var response = params["response"];
                        $.post(
                            "/en/cart/authorizenet_postprocess/?",
                            {
                                original_response: response,
                                csrfmiddlewaretoken: AuthorizeNetPopup.csrf_token,
                            },
                            function (data, status, xhr) {
                                window.location = data['redirect_to']
                            });
                        AuthorizeNetPopup.closePopup();
                        break;
                }
            };

            function parseQueryString(str) {
                var vars = [];
                var arr = str.split('&');
                var pair;
                for (var i = 0; i < arr.length; i++) {
                    pair = arr[i].split('=');
                    vars.push(pair[0]);
                    vars[pair[0]] = unescape(pair[1]);
                }
                return vars;
            }
        }

    })(jQuery);
});