import jQuery from 'jquery';
import './jquery.oska-spinner' ;

(function ($) {
    var ProductDetail = window.ProductDetail = {
        run: function (options) {
            let {
                p_id,
                html_variant_data,
                price_variant_data,
                count_variant_data,
                product_display_max_price_orig,
                non_variant_tag_id,
                wishlist_items,
                fb_pixel_data,
                url,
            } = options

            const product_color_group = html_variant_data.find(data => data.is_color);
            const product_size_group = html_variant_data.find(data => !data.is_color);

            function updateSizeAndColorQueryParams() {
                 let url = new URL(window.location.href);
                 url.searchParams.set('sizes', get_selected_size_obj_id());
                 url.searchParams.set('color', get_selected_color_obj_id());
                 url.searchParams.delete('gcolors');
                 window.history.replaceState({}, '', url);
            }

            function update_sale_badges() {
                const $color_inputs = $(`#p_${p_id}_color_selection__js input`);
                $color_inputs.each(function () {
                    let $input = $(this);
                    let color_obj_id = $input.val();

                    if (product_color_group.items.find(i => `${i.obj_id}` === color_obj_id)?.sale_value) {
                        $input.addClass("on-sale-badge")
                    } else {
                        $input.removeClass("on-sale-badge")
                    }
                })

                const $size_inputs = $(`#p_${p_id}_sizeSelection__js input`);
                $size_inputs.each(function () {
                    let $input = $(this);
                    let sizeObjId = id_to_obj_id(product_size_group.group_id, $input.val());

                    let price = price_variant_data[`${get_selected_color_id()},${sizeObjId}`];

                    if (price && product_display_max_price_orig !== price) {
                        $input.parent().addClass("on-sale-badge")
                    } else {
                        $input.parent().removeClass("on-sale-badge")
                    }

                })

            }

            function update_price() {
                let discounted_price = price_variant_data[`${get_selected_color_id()},${get_selected_size_id()}`];
                if (discounted_price && discounted_price !== product_display_max_price_orig) {
                    $(`#p_${p_id}_original_price__js`).text(product_display_max_price_orig)
                } else {
                    $(`#p_${p_id}_original_price__js`).text("")
                }
                $(`#p_${p_id}_current_price__js`).text(discounted_price ?? product_display_max_price_orig)
            }

            function remove_alerts(form) {
                // smazeme drive zobrazene hlasky
                form.find('.alert').remove();
                form.nextAll('.alert').remove();
            }

            function id_to_obj_id(groupId, id) {
                return `v_${groupId}_${id}`
            }

            function get_selected_color_obj_id() {
                return $(`#p_${p_id}_color_selection__js input:checked`).val()
            }

            function get_selected_color_id() {
                return id_to_obj_id(product_color_group.group_id, get_selected_color_obj_id())
            }

            function get_selected_size_obj_id() {
                return $(`#p_${p_id}_sizeSelection__js input:checked`).val()
            }

            function get_selected_size_id() {
                return id_to_obj_id(product_size_group.group_id, get_selected_size_obj_id())
            }

            function update_product_status() {
                const sizeId = get_selected_size_id()
                const colorId = get_selected_color_id()
                let itemsInStock = count_variant_data[colorId]?.[sizeId] ?? 0;

                let productStatus = ""
                if (itemsInStock === 0) {
                    productStatus = `<div class="text-danger"><i class="bi bi-reception-0"></i> ${gettext("Out of stock")}</div>`
                    $(`#p_${p_id}_add_to_cart_button__js`).prop('disabled', true);
                } else {
                    $(`#p_${p_id}_add_to_cart_button__js`).prop('disabled', false);
                    if (itemsInStock === 1) {
                        productStatus = `<div class="text-warning"><i class="bi bi-reception-2"></i> ${gettext("Only one left")}</div>`
                    } else if (itemsInStock <= 5) {
                        productStatus = `<div class="text-warning"><i class="bi bi-reception-2"></i> ${gettext("Only few left")}</div>`
                    } else {
                        productStatus = `<div class="text-success"><i class="bi bi-reception-4"></i> ${gettext("In stock")}</div>`
                    }
                }
                $(`#p_${p_id}_product-status__js`).html(productStatus)
            }

            function update_product_image() {
                const $flickity_container = $(`#p_${p_id}_flickity`);
                const flickity_data = Flickity.data($flickity_container[0]);
                const colorId = get_selected_color_id();
                const $all_items = $flickity_container.find('.product-detail-slide img,video');
                // musime pracovat se setem `all_items`, protože detail produktu se používa i v detailu outfitu
                const $final_item = $all_items.filter(`[data-product-image-color-id=${colorId}]`);
                let indexOfImage = $all_items.index($final_item);

                // posledni obrazek - sorry, no image available
                if(indexOfImage === -1){
                    indexOfImage = flickity_data.cells.length - 1;
                }

                if(flickity_data !== flickity_data.selectedIndex){
                    flickity_data.select(indexOfImage)
                }
            }

            // zmena barvy prekresli varianty a vybere obrazek
            function update_sizes() {
                const color_id = get_selected_color_id()

                $(`#p_${p_id}_sizeSelection__js input`).each(function () {
                    let size_input_element = $(this);
                    let size_id = size_input_element.val();
                    let items_in_stock = count_variant_data[color_id]?.[id_to_obj_id(product_size_group.group_id, size_id)] ?? 0;

                    if (items_in_stock === 0) {
                        $(size_input_element.parent()).addClass('not-available-in-stock')
                    } else {
                        $(size_input_element.parent()).removeClass('not-available-in-stock')
                    }
                })
            }
            function update_color_label_and_size() {
                const color_id = get_selected_color_id()
                const size_id = get_selected_size_id()

                $(`#p_${p_id}_color_caption`).text(product_color_group.items.find(i => i.id === color_id).title);
                $(`#p_${p_id}_size_caption`).text(product_size_group.items.find(i => i.id === size_id).title);
            }

            function is_current_product_in_wishlist() {
                return wishlist_items.map(i => i.dtags).includes(`,${get_selected_size_obj_id()},${get_selected_color_obj_id()},`)
            }

            function update_wishlist() {
                const $add_to_wishlist_icon = $(`#p_${p_id}_add-wishlistitem_icon__js`);
                if(is_current_product_in_wishlist()){
                    $add_to_wishlist_icon.removeClass("bi bi-heart")
                    $add_to_wishlist_icon.addClass("bi bi-heart-fill text-primary")
                } else {
                    $add_to_wishlist_icon.removeClass("bi bi-heart-fill text-primary")
                    $add_to_wishlist_icon.addClass("bi bi-heart")
                }
            }

            function update_product_detail() {
                update_price();
                update_color_label_and_size();
                update_sizes();
                update_wishlist()
                update_sale_badges();
                update_product_status();
                update_product_image();
                updateSizeAndColorQueryParams();
            }

            $(`#p_${p_id}_add-wishlistitem__js`).click(function () {
                const $add_to_wishlist_button = $(this);
                const $form = $(`#p_${p_id}_add_to_cart_form__js`)
                // smazeme drive zobrazene hlasky
                remove_alerts($form);

                const product_data = Object.fromEntries(new FormData($form.get(0)))
                product_data.action = is_current_product_in_wishlist() ? "remove": "add";
                product_data.non_variant_tag_id = non_variant_tag_id ? non_variant_tag_id : ""
                const ajaxPromise = $.ajax({
                    type: "post",
                    url: url.product_change_favorite,
                    data: JSON.stringify(product_data),
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                        "X-CSRFToken": product_data.csrfmiddlewaretoken
                    },
                    success: function (data) {
                        wishlist_items = data['wishlist_items']
                        const messagesHtml = data["messages"].map(msg => '<div class="alert alert-cart alert-' + msg['class'] + '"><div>' + msg['message'] + '</div>');
                        $form.after(messagesHtml)
                        update_product_detail()
                    },
                });
                $add_to_wishlist_button.oska_spinner(ajaxPromise)
            })

            if (fb_pixel_data) {
                if (typeof fbq !== 'undefined') {
                    fbq('track', 'ViewContent', fb_pixel_data);
                }
            }

            $(`#p_${p_id}_add_to_cart_form__js`).submit(function (e) {
                e.preventDefault()
                var $add_to_cart_button = $(`#p_${p_id}_add_to_cart_button__js`);
                var form = $add_to_cart_button.closest('form');

                // smazeme drive zobrazene hlasky
                form.find('.alert').remove();
                form.nextAll('.alert').remove();

                const form_data_tmp = new FormData(e.target);
                const prod_data = Object.fromEntries(form_data_tmp);
                const form_data = {
                    csrfmiddlewaretoken: prod_data.csrfmiddlewaretoken,
                    product_data: JSON.stringify(prod_data),
                }

                // odeslani formulare
                const ajaxPromise = $.ajax({
                    type: 'POST',
                    url: form.attr('action'),
                    data: form_data,
                    success: function (data) {
                        const msg = data.message;
                        if (msg) {
                            let message = '<div class="alert alert-' + msg.class + ' d-flex align-items-center justify-content-between">'
                            message += '<div>' + msg.text + '</div>'
                            message += msg.show_cart_button ? `<a class="btn btn-success btn-sm" href="${url.order_cart}">${gettext("Go to cart")}</a>` : ''
                            message += '</div>';
                            form.after(message);
                            // update cart count
                            $(`#cart_with_item_count`).attr('data-cart-items', data.cart_total_count);
                            if (data.matomo_data_items) {
                                if (typeof _paq !== 'undefined') {
                                    for (const item of data.matomo_data_items) {
                                        console.debug("[MATOMO] PUSHING (add to SB):", item);
                                        _paq.push(item);
                                    }
                                }
                            }
                        }
                    },
                    error: function (error) {
                        // Zpracování chyby
                        console.error('Error:', error);
                    },
                    complete: () => {
                        update_product_detail()
                    }
                });
                $add_to_cart_button.oska_spinner(ajaxPromise)
            });

            $(`#p_${p_id}_color_selection__js input`).change(function () {
                update_product_detail()
            });

            $(`#p_${p_id}_sizeSelection__js input`).change(function () {
                update_product_detail()
            })

            update_product_detail()
        }
    }
})(jQuery);