import jQuery from 'jquery';

document.addEventListener('DOMContentLoaded', function () {

    (function ($) {

        const $select = $('#pickup_place_select__js');
        if ($select.length) {
            let $address = $('#pickup_address_box__js');
            const addresses_data = JSON.parse($select.attr('data-addresses'));

            function updateAddress() {
                var selected_id = $select.val();
                if (selected_id && addresses_data[selected_id]) {
                    var data = addresses_data[selected_id];
                    $address.html(`<i class="fe fe-map-pin me-2"><a href="${data.map}" target="_blank">${data.address}</a>`);
                } else {
                    $address.html('');
                }
            }

            $select.on('change', updateAddress);
            updateAddress();
        }
    })(jQuery);
});